import { render, staticRenderFns } from "./Frame.vue?vue&type=template&id=c64d0a36&scoped=true&"
import script from "./Frame.vue?vue&type=script&lang=js&"
export * from "./Frame.vue?vue&type=script&lang=js&"
import style0 from "./Frame.vue?vue&type=style&index=0&id=c64d0a36&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c64d0a36",
  null
  
)

export default component.exports